(function(){
    'use strict';

    angular.module('informaApp')
        .component('savedSearchesList', {
            templateUrl: 'components/home/saved-searches-list/template.html',
            controller: SavedSearchesListController,
            bindings: {
                onAddSavedSearch: '<',
                onStartLoadingSearch: '<'
            }
        });

    function SavedSearchesListController(SaveSearchSvc, FilterService, ConstantsSvc, TableGroupsRoutes) {
        this.searches = null;
        this.defaultSearches = [
            { text: 'Loading...' }
        ];
        this.emptyData = [
            { text: 'No Saved Searches Found' }
        ];
        this.tableGroups = generateTableGroups(ConstantsSvc, TableGroupsRoutes);

        this.onAddSavedSearchClick = () => {
            if (this.searches) {
                return;
            }

            SaveSearchSvc.getAllUserSearches()
                .then(searches => {
                    this.searches = searches.length
                        ? mapSavedSearchList.call(this, searches)
                        : this.emptyData;
                });
        };

        this.loadSavedSearchData = (search, tableGroupIndex = null) => {
            if (this.onStartLoadingSearch) {
                this.onStartLoadingSearch();
            }

            const targetTableGroupIndex = Number.isInteger(tableGroupIndex)
                ? tableGroupIndex
                : search.activeTableGroupIndex;

            FilterService.getResult(targetTableGroupIndex, search.filter)
                .then(searchData => mapSavedSearchData(search, searchData))
                .then(searchData => this.onAddSavedSearch(searchData));
        };
    }

    function mapSavedSearchData(search, searchData) {
        return (searchData || []).map(x => ({
            ...x,
            originSearchName: search.name,
            groupIndex: search.activeTableGroupIndex
        }));
    }

    function mapSavedSearchList(searches) {
        return searches.map(search => ({
            text: search.name,
            subData: mapTableGroups.call(this, search)
        }));
    }

    function mapTableGroups(search) {
        return this.tableGroups.map(tableGroup => ({
            text: tableGroup.name,
            onClick: () => this.loadSavedSearchData(search, tableGroup.id),
            note: search.activeTableGroupIndex === tableGroup.id ? 'saved' : null
        }));
    }

    function generateTableGroups(ConstantsSvc, TableGroupsRoutes) {
        const tableGroupRoutesArray = Object.values(TableGroupsRoutes);

        return Object.values( ConstantsSvc.tableGroupsName)
            .map((group, index) => ({
                ...group,
                id: tableGroupRoutesArray[index].id
            }));
    }
})();
